var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "Drawer",
        {
          attrs: { visible: _vm.visible, title: "权限", size: "small" },
          on: { close: _vm.onClose, onSubmit: _vm.onSubmit },
        },
        [
          _c(
            "div",
            [
              _c(
                "a-checkbox",
                {
                  style: { display: _vm.isNone },
                  attrs: { checked: _vm.checkAll },
                  on: { change: _vm.onCheckAllChange },
                },
                [_vm._v(" 全选 ")]
              ),
              _c("a-tree", {
                attrs: {
                  checkable: "",
                  "auto-expand-parent": true,
                  "tree-data": _vm.treeData,
                  "replace-fields": _vm.replaceFields,
                },
                on: { check: _vm.onCheck },
                model: {
                  value: _vm.checkedKeys,
                  callback: function ($$v) {
                    _vm.checkedKeys = $$v
                  },
                  expression: "checkedKeys",
                },
              }),
            ],
            1
          ),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }