import { GLOBAL_SETTING } from '../api'
import { METHOD, request } from '@/utils/request'

// 获取账号列表
export async function getAccountList(params) {
  return request(`${GLOBAL_SETTING}/account/getAccountList`, METHOD.GET, params)
}

// 获取功能树
export async function getFuncTree(params) {
  return request(`${GLOBAL_SETTING}/account/getFuncTree`, METHOD.GET, params)
}

// 账号启用/停用
export async function updateUserStatus(params) {
  return request(`${GLOBAL_SETTING}/account/updateUserStatus`, METHOD.POST, params)
}

// 更新账号是否可操作权限设置
export async function updateUserAuthority(params) {
  return request(`${GLOBAL_SETTING}/account/updateUserAuthority`, METHOD.POST, params)
}

// 添加账号
export async function createAccount(params) {
  return request(`${GLOBAL_SETTING}/account/createAccount`, METHOD.POST, params)
}

// 更新账号应用权限
export async function updateUserFunction(params) {
  return request(`${GLOBAL_SETTING}/account/updateUserFunction`, METHOD.POST, params)
}

// 删除账号信息
export async function deleteAccount(params) {
  return request(`${GLOBAL_SETTING}/account/deleteAccount`, METHOD.POST, params)
}

// 获取账号绑定的功能
export async function getAccountFunc(params) {
  return request(`${GLOBAL_SETTING}/account/getAccountFunc`, METHOD.GET, params)
}
// 获取转让账号下拉列表
export async function getTransferAccountList(params) {
  return request(`${GLOBAL_SETTING}/account/getTransferAccountList`, METHOD.GET, params)
}
// 创建人转让
export async function transferAccount(params) {
  return request(`${GLOBAL_SETTING}/account/transferAccount`, METHOD.POST, params)
}

// 账号解冻
export async function resetPassword(params) {
  return request(`${GLOBAL_SETTING}/account/resetPassword`, METHOD.POST, params)
}
