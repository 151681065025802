var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "Modal",
        {
          attrs: {
            closable: true,
            visible: _vm.visible,
            title: "转让创建人",
            size: "small",
            "ok-text": _vm.isTab ? "下一步" : "确定",
          },
          on: { ok: _vm.handleOk, cancel: _vm.handleCancel },
        },
        [
          _vm.isTab
            ? _c(
                "div",
                { staticClass: "first" },
                [
                  _c("p", [_vm._v("为确保数据安全，请进行身份验证")]),
                  _c("a-space", [
                    _c("span", [_vm._v("手机号")]),
                    _c("span", [_vm._v(_vm._s(_vm.getInfo.phoneNum))]),
                  ]),
                  _c(
                    "a-space",
                    [
                      _c("span", [_vm._v("验证码")]),
                      _c("GetCheckCode", {
                        ref: "codeRef",
                        attrs: {
                          "can-use": String(_vm.getInfo.phoneNum).length !== 0,
                        },
                        on: { getCode: _vm.getVerifyCode },
                        model: {
                          value: _vm.verifyCode,
                          callback: function ($$v) {
                            _vm.verifyCode =
                              typeof $$v === "string" ? $$v.trim() : $$v
                          },
                          expression: "verifyCode",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              )
            : _vm._e(),
          !_vm.isTab
            ? _c(
                "div",
                { staticClass: "second" },
                [
                  _c(
                    "a-space",
                    [
                      _c("span", [_vm._v("对接人")]),
                      _c(
                        "a-select",
                        {
                          attrs: { placeholder: "请选择对接人" },
                          on: { change: _vm.handleChange },
                          model: {
                            value: _vm.transferUserId,
                            callback: function ($$v) {
                              _vm.transferUserId = $$v
                            },
                            expression: "transferUserId",
                          },
                        },
                        _vm._l(_vm.getData, function (item) {
                          return _c("a-select-option", { key: item.userId }, [
                            _vm._v(
                              " " +
                                _vm._s(item.userName) +
                                " " +
                                _vm._s(item.phoneNum) +
                                " "
                            ),
                          ])
                        }),
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              )
            : _vm._e(),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }