<template>
  <div class="backs">
    <!--  表格  -->
    <div>
      <div class="addCount">
        <a-button type="primary" prefix="add-one" @click="handleAdd">
          添加账号
        </a-button>
      </div>
      <custom-table
        :columns="columns"
        :data-source="dataSource"
        table-ref=".backs"
        row-key="userId"
        :pagination="{
          current: pager.pageNum,
          pageSize: pager.pageSize,
          total: total,
          showTotal: (total) => `共 ${total} 条数据`,
          showQuickJumper: true,
          showSizeChanger: true
        }"
        @change="loadDataList"
      >
        <!-- 序号 -->
        <template slot="index" slot-scope="{ scope }">
          <span>{{ scope.index + 1 }}</span>
        </template>
        <!-- 姓名头像 -->
        <template slot="userName" slot-scope="{ scope }" style="height: 51px">
          <span>{{ scope.record.userName }}</span>
          <span class="createTag">
            <a-tag v-if="scope.record.isCreator === '1'" color="orange">创建人</a-tag>
          </span>
        </template>
        <template slot="userStatus" slot-scope="{ scope }">
          <a-switch v-if="scope.record.isCreator != '1'" size="small" :checked="scope.record.userStatus === '0'" @click="switchChange(scope.record)" />
        </template>
        <template v-if="isCreator === '1'" slot="isAuthority" slot-scope="{ scope }">
          <a-switch v-if="scope.record.isCreator != '1'" size="small" :checked="scope.record.isAuthority === '1'" @click="isAuthorityChange(scope.record)" />
        </template>
        <!-- 操作列 -->
        <template slot="operation" slot-scope="{ scope }">
          <a-button
            v-if="scope.record.isCreator === '1' && isCreator === '1'"
            type="link"
            @click="onTransfer(scope.record)"
          >
            转让创建人
          </a-button>
          <a-popover v-if="scope.record.isCreator != '1'" placement="bottomLeft" trigger="hover">
            <template slot="content">
              <div class="more-handle-btn">
                <a-button
                  v-if="scope.record.frozen === 1 && scope.record.isCreator !== '1' && isCreator === '1'"
                  type="link"
                  @click="thaw(scope.record.userId)"
                >
                  解冻
                </a-button>
                <a-button type="link" @click="permissions(scope.record.userId)"> 权限 </a-button>
                <a-popconfirm title="是否确认删除？" trigger="click" ok-text="确认" cancel-text="取消" @confirm="deleteConfirm(scope.record.userId)">
                  <a-button class="delete" type="link"> 删除 </a-button>
                </a-popconfirm>
              </div>
            </template>
            <div class="more-handle">···</div>
          </a-popover>
        </template>
      </custom-table>
    </div>
    <AddAuthor ref="transferForm" @getFetchAddIn="getFetchAddIn" />
    <TransferCreator ref="transferType" @transfer="changeTransfer" />
    <Permissions ref="permissionForm" @getPermission="getPermission" />
  </div>
</template>

<script>
import mixins from '@/components/CustomTable/index.js'
import AddAuthor from './addAuthor.vue'
import TransferCreator from './transferCreator.vue'
import Permissions from './permissionsTree.vue'
import {
  getAccountList,
  updateUserStatus,
  deleteAccount,
  getAccountFunc,
  updateUserAuthority,
  resetPassword }
  from '@/services/globalSetting/settingAuthor.js'
export default {
  name: 'SettingAuthor',
  components: {
    AddAuthor,
    TransferCreator,
    Permissions
  },
  mixins: [mixins],
  data() {
    return {
      total: 0,
      columns: [
        {
          title: '序号',
          dataIndex: 'index',
          scopedSlots: { customRender: 'index' },
          width: 50,
          align: 'center'
        },
        {
          title: '姓名',
          dataIndex: 'userName',
          scopedSlots: { customRender: 'userName' }
        },
        {
          title: '手机号',
          dataIndex: 'phoneNum',
          key: 'phoneNum',
          ellipsis: true
        },
        {
          title: '最近登陆时间',
          dataIndex: 'loginTime',
          key: 'loginTime'
        },
        {
          title: '添加时间',
          dataIndex: 'createTime',
          key: 'createTime'
        },
        {
          title: '启用/停用',
          dataIndex: 'userStatus',
          key: 'userStatus',
          scopedSlots: { customRender: 'userStatus' }
        },
        {
          title: '冻结状态',
          dataIndex: 'frozenName',
          key: 'frozenName'
        },
        {
          title: '是否开启权限设置',
          dataIndex: 'isAuthority',
          key: 'isAuthority',
          scopedSlots: { customRender: 'isAuthority' }
        },
        {
          title: '操作',
          dataIndex: 'operation',
          scopedSlots: { customRender: 'operation' }
        }
      ],
      dataSource: [],
      tempTypeId: '',
      isCreator: '',
      page: {
        pageNum: 1,
        pageSize: 10
      }
    }
  },
  mounted() {
    const { isCreator } = JSON.parse(localStorage.getItem(process.env.VUE_APP_ACCOUNT_KEY))
    this.isCreator = isCreator
    if (isCreator !== '1') {
      this.columns.splice(6, 1)
    }
  },
  methods: {
    thaw(userId) {
      this.$Reconfirm({
        title: '提示',
        content: '是否确认解冻当前账号？解冻后密码将重置为手机号后8位。'
      }).then(async() => {
        await resetPassword({ userId })
        this.serchGetData()
        this.$message.success('解冻成功！')
      })
    },
    // 获取数据
    async fetchDataApiFn(param) {
      const res = await getAccountList(param)
      this.dataSource = res.data.records ? res.data.records : []
      this.dataSource = this.dataSource.map(item => {
        return {
          ...item,
          // 是否被冻结，0正常未被冻结 1被冻结
          frozenName: item.frozen === 0 ? '未冻结' : '冻结'
        }
      })
      this.total = res.data.total
      this.pager.pageNum = res.data.current || 1 // 更新当前的分页信息
      this.pager.pageSize = res.data.size || 10 // 更新当前的分页信息
    },
    // 启用/停用
    async switchChange(info) {
      let status = '0'
      if (info.userStatus === '0') {
        status = '1'
      } else {
        status = '0'
      }
      const res = await updateUserStatus({
        userId: info.userId,
        userStatus: status
      })
      if (res.code === 100) {
        this.$message.success('修改状态成功')
        this.serchGetData()
      }
    },
    // 是否开始权限设置，当前用户是否有权限设置这个二级菜单的权限
    async isAuthorityChange(info) {
      let isAuthority = '0'
      if (info.isAuthority === '0') {
        isAuthority = '1'
      } else {
        isAuthority = '0'
      }
      await updateUserAuthority({ userId: info.userId, isAuthority })
      this.$message.success('修改状态成功')
      this.serchGetData()
    },
    // 添加账号
    handleAdd() {
      this.$refs.transferForm.open()
    },
    // 操作回调
    getFetchAddIn() {
      this.serchGetData()
    },
    // 转让创建人
    onTransfer(info) {
      this.$refs.transferType.open({
        getInfo: info
      })
    },
    changeTransfer() {
      this.fetchDataApiFn(this.page)
    },
    async deleteConfirm(val) {
      const res = await deleteAccount({ userId: val })
      if (res.code === 100) {
        this.$message.success('删除成功')
        this.serchGetData()
      }
    },
    async permissions(val) {
      const res = await getAccountFunc({ userId: val })
      res.data.forEach((item, index) => {
        if (item === '905' || item === '810') {
          return res.data.splice(index, 1)
        }
      })
      this.$refs.permissionForm.open({ userId: val, funcIdList: res.data })
    },
    getPermission() {
      this.serchGetData()
    }
  }
}
</script>

<style lang="less" scoped>
.backs{
  background-color: #fff;
  padding: 20px 20px 0;
  min-height: 100%;
}
.addCount{
  margin-bottom: 20px;
}
.createTag{
  margin-left: 10px;
}
.more-handle-btn {
  display: flex;
  flex-direction: column;
}
.more-handle {
  color: @sc-primary-100;
  font-weight: 700;
  margin-left: 5px;
  font-size: 20px;
}
/deep/ .ant-switch-small {
  margin-left: 18px;
}
</style>
